.ball {
    width:140px;
    height:140px;
    right: -90%;
    margin-top: 0;
    scale: 0.4;
    border-radius:100%;
    background: #991b1b;
    animation: bounce 1s;
    transform: translateY(0px);
    animation-iteration-count: infinite;
    position: relative;

    @media (min-width: 576px) { 
        margin-top: 0;
        scale: 0.8;
    }

    @media (min-width: 992px) {
        margin-top: 20%;
        scale: 1;
    }

    @media (min-width: 1110px) {
        display: none;
    }
}

@keyframes bounce {
    0% {top: 0;
        -webkit-animation-timing-function: ease-in;
    }
    40% {}
    50% {top: 140px;
        height: 140px;
        -webkit-animation-timing-function: ease-out;
    }
    55% {top: 160px; height: 120px; 
        -webkit-animation-timing-function: ease-in;}
    65% {top: 120px; height: 140px; 
        -webkit-animation-timing-function: ease-out;}
    95% {
        top: 0;		
        -webkit-animation-timing-function: ease-in;
    }
    100% {top: 0;
        -webkit-animation-timing-function: ease-in;
    }
}