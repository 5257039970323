/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    transition: all 150ms ease-in-out;

    &__content {
        margin: 0 auto; /* 15% from the top and centered */
        width: 50%; /* Could be more or less, depending on screen size */
    }
}

.styledLink {
    color: inherit;
    text-decoration: inherit;
}

#usernameModal, #jiraModal, #selectRoomModal, #contactUsModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

#usernameModal.modal-open #usernameModalContainer {
    opacity: 1;
}

#jiraModal.modal-open #jiraModalContainer {
    opacity: 1;
}

#selectRoomModal.modal-open #selectRoomModalContainer {
    opacity: 1;
}

#contactUsModal.modal-open #contactUsModalContainer {
    opacity: 1;
}

// input[type="text"] {
//     width: 100%;
//     margin-bottom: 20px;
//     display: inline-block;
//     border: 1px solid #ccc;
//     box-shadow: inset 0 1px 3px #ddd;
//     border-radius: 4px;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
//     padding-left: 20px;
//     padding-right: 20px;
//     padding-top: 12px;
//     padding-bottom: 12px;
// }

// #issueInput {
//     width: 100%;
//     margin-bottom: 20px;
//     display: inline-block;
//     border: 1px solid #ccc;
//     box-shadow: inset 0 1px 3px #ddd;
//     border-radius: 4px;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
//     padding-left: 20px;
//     padding-right: 20px;
//     padding-top: 12px;
//     padding-bottom: 12px;
// }

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal__content {
    margin-top: 15%;
}
