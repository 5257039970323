$dark: #001e82;
$light: #b2e0fd;
$light-50: #d8effe;
$light-25: #ecf7fe;

.table {
    background-color: $light;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__grid {
        grid-gap: 0.8rem;
        display: inline-grid;
        grid-template-areas:
            "left top right"
            "left table right"
            "left bottom right";
        grid-template-columns: 10.4rem 1fr 10.4rem;
        grid-template-rows: 10rem 1fr 10rem;
        margin: 0 auto;
        height: 700px;
        width: 1000px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        margin-top: auto;
    }
}

.card {
    border-style: none;
    background-color: $dark;
    color: #000000;
    font-size: 20pt;
    width: 2.5em;
    height: 3.5em;
    margin-top: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__no-choice {
        border-style: none;
        background-color: lightgray;
        color: #000000;
        font-size: 20pt;
        width: 2.5em;
        height: 3.5em;
        margin-top: 0px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__individual {
        padding-left: 25px;
        padding-right: 25px;
        margin: 10px;
        text-align: center;
    }

    &__visible {
        border-style: none;
        margin-top: 0px;
        background-color: $light;
        font-size: 20pt;
        width: 2.5em;
        height: 3.5em;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__spectator {
        border-style: none;
        margin-top: 0px;
        background-color: lightgrey;
        font-size: 20pt;
        width: 2.5em;
        height: 3.5em;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__container {
        display: flex;
        justify-content: center;

        &__vertical {
            flex-direction: column;
        }
    }
}

.eye {
    width: 40px;
    fill: grey;
}

.spectator-text {
    color: #48545d;
    font-size: 16px;
    font-weight: bold;
}

.spectatorContainer {
    position: absolute;
    left: 20px;
    top: 80px;
}