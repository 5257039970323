$light-50: #d8effe;

.dropdown {
    position: relative;
    display: inline-block;

    &:hover .dropdown__button {
        background-color: #f5f5f5;
    }

    &:hover .dropdown__content {
        display: block;
    }

    &__button {
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        background-color: white;
        color: #48545d;
        padding: 16px;
        font-size: 16px;
        min-width: 180px;
        border: none;
        cursor: pointer;
    }

    &__content {
        border-radius: 10px;
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    &__item {
        all: unset;
        cursor: pointer;
        min-width: 160px;
        padding: 5px 10px;

        &:hover {
            background-color: $light-50;
        }

        &__content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 5px;
        }
    }
}

.icon {
    fill: #48545d;

    &__caret-down {
        width: 10px;
    }
}

.icon__user {
    fill: #FFF;
    margin: 6px auto auto auto;
    width: 16px;
}
