.ticket {
    border-radius: 10px;
    background-color: #f4f5f7;
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
}

.ticket-mobile {
    width: 80%;
}

.ticket-header {
    text-align: left;
    display: flex;
    margin-bottom: 20px;
}

.ticket-footer {
    display: flex;
    justify-content: space-between;

    &-left {
        display: flex;
    }

    &-right {
        display: flex;
    }
}
