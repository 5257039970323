$dark: #001e82;
$light: #b2e0fd;
$light-50: #d8effe;
$light-25: #ecf7fe;

.result-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #0a6bff;
}

.ScrollStyle {
    display: flex;
    height: 100%;
}

.styled-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #001e82;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

// .styled-table tbody tr:last-of-type {
//     border-bottom: 2px solid #001e82;
// }

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #001e82;
}

.result__card {
    border-radius: 10px;
    border-style: none;
    background-color: $dark;
    color: #000000;
    font-size: 20pt;
    width: 2.5em;
    height: 3.5em;
    margin-top: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__visible {
        border-radius: 10px;
        border-style: none;
        margin-top: 0px;
        background-color: $light;
        font-size: 20pt;
        width: 2em;
        height: 2.5em;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
