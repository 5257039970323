$dark: #001e82;
$light: #b2e0fd;
$light-50: #d8effe;
$light-25: #ecf7fe;

$btn-primary: #1d4ed8;

.btn {

    &:hover {
        transform: translateY(-2px);
    }

    &__active {
        transform: translateY(-5px);
    }

    &__disabled {
        background-color: #dadddf;
    }
}

.form__group {
    position: relative;
    width: 50px;
}
